import styles from "./styles.module.scss";
import {Button, Icon} from "@shopify/polaris";
import {
  CartMajor, PhoneMajor,CalendarMajor
} from '@shopify/polaris-icons';
import useTranslation from "next-translate/useTranslation";
import {APP_STORE_LINK, COOKIE_ANALYTICS, COOKIE_FUNCTIONALITY} from "../../../../utils/constants";
import {signUtmParams} from "../../../../helpers/signUtmParams";
import {useRouter} from "next/router";
import {COMMON_NAMESPACE} from "../../../../utils/i18nNameSpaces";
import {checkUsableCookie} from "../../../../helpers/cookie/checkUsableCookie";
import {useContext} from "react";
import {COOKIE_CONTEXT} from "../../../providers/CookieProvider";

const iconsArr = [CartMajor, CalendarMajor, PhoneMajor]

const CTA = ({link = APP_STORE_LINK}) => {
  const {t} = useTranslation(COMMON_NAMESPACE);
  const router = useRouter();
  const {openBanner, openCookieToast, isBannerOpen} = useContext(COOKIE_CONTEXT);
  const cta = [
    {
      "iconSrc": "/polarisIcons/truck.svg",
      "header": t("cta._1.header"),
      "subheader": t("cta._1.subheader"),
      "link": {
        "text": t("cta._1.link"),
        "href": APP_STORE_LINK
      }
    },
    {
      "iconSrc": "/polarisIcons/trial.svg",
      "header": t("cta._2.header"),
      "subheader": t("cta._2.subheader"),
      "link": {
        "text": t("cta._2.link"),
        "href": APP_STORE_LINK
      }
    }
  ]

  const bookACall = () => {
    if (!checkUsableCookie([COOKIE_FUNCTIONALITY, COOKIE_ANALYTICS])) {
      if (isBannerOpen) {
        openCookieToast(COOKIE_FUNCTIONALITY)
      }
      openBanner([COOKIE_FUNCTIONALITY, COOKIE_ANALYTICS]);

      return;
    }

    window.__supportBookACall && window.__supportBookACall()
    return false;
  }

  return (
    <section className={styles["cta"]}>
      <div className={styles["cta__container"]}>
        <ul className={styles["cta__carts-list"]}>
          <li className={styles["cta__cart"]}>
            <div className={"cta__icon-wrapper"}>
              <Icon source={CartMajor}/>
            </div>
            <div className={"cta__cart-content"}>
              <h4 className={styles["cta__cart-header"]}>{t("cta._1.header")}</h4>
              <p className={styles["cta__cart-subheader"]}>{t("cta._1.subheader")}</p>
            </div>
            <footer className={styles["cta__cart-footer"]}>
              <div className={styles["cta__cart-link"]}>
                <Button
                  external
                  url={signUtmParams(link, router)}
                >{t("cta._1.link")}</Button>
              </div>
            </footer>
          </li>
          <li className={styles["cta__cart"]}>
            <div className={"cta__icon-wrapper"}>
              <Icon source={CalendarMajor}/>
            </div>
            <div className={"cta__cart-content"}>
              <h4 className={styles["cta__cart-header"]}>{t("cta._2.header")}</h4>
              <p className={styles["cta__cart-subheader"]}>{t("cta._2.subheader")}</p>
            </div>
            <footer className={styles["cta__cart-footer"]}>
              <div className={styles["cta__cart-link"]}>
                <Button
                  external
                  onClick={bookACall}
                >{t("cta._2.link")}</Button>
              </div>
            </footer>
          </li>
          <li className={styles["cta__cart"]}>
            <div className={"cta__icon-wrapper"}>
              <Icon source={PhoneMajor}/>
            </div>
            <div className={"cta__cart-content"}>
              <h4 className={styles["cta__cart-header"]}>{t("cta._3.header")}</h4>
              <p className={styles["cta__cart-subheader"]}>{t("cta._3.subheader")}</p>
            </div>
            <footer className={styles["cta__cart-footer"]}>
              <div className={styles["cta__cart-link"]}>
                <Button
                  external
                  onClick={bookACall}
                >{t("cta._3.link")}</Button>
              </div>
            </footer>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default CTA;